import { useEffect } from 'react';

const ZendeskChatbot = ({ primary }) => {
  useEffect(() => {
    let script;

    // check if zendesk widget is already loaded
    if (primary?.key && !window.zE) {
      script = document.createElement('script');
      script.async = true;
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${primary.key}`;
      script.addEventListener('load', () => {
        const chatbotSkip = document.querySelector('.chatbot-skip');

        if (chatbotSkip) {
          chatbotSkip.classList.add('-active');
        }
      });
      document.body.appendChild(script);
    }

    return () => {
      if (primary?.key && window.zE) {
        document.body.removeChild(script);
        delete window.zE;
        delete window.zEInit;
        delete window.zESettings;
      }
    };
  }, [primary?.key]);

  return null;
};

export default ZendeskChatbot;
