import React from 'react';

// Components
import NavItem from '../components/nav-item/nav-item';
import PullQuote from '../components/pull-quote/pull-quote';
import Subpage from '../pages/subpage';

// Layouts
import Banner from '../layouts/banner/banner';
import CallToAction from '../layouts/call-to-action/call-to-action';
import CampaignColumns from '../layouts/campaign-columns/campaign-columns';
import CampaignComparisonTable from '../layouts/campaign-comparison-table/campaign-comparison-table';
import CampaignFeature from '../layouts/campaign-feature/campaign-feature';
import CampaignFeatureProfiles from '../layouts/campaign-feature-profiles/campaign-feature-profiles';
import CampaignHeader from '../layouts/campaign-header/campaign-header';
import CampaignOneColumn from '../layouts/campaign-one-column/campaign-one-column';
import CampaignTestimonials from '../layouts/campaign-testimonials/campaign-testimonials';
import CampaignTrustpilot from '../layouts/campaign-trustpilot/campaign-trustpilot';
import CampaignTwoColumn from '../layouts/campaign-two-column/campaign-two-column';
import Careers from '../layouts/careers/careers';
import Checklist from '../layouts/checklist/checklist';
import ContentToggle from '../layouts/content-toggle/content-toggle';
import ContentTabbed from '../layouts/content-tabbed/content-tabbed';
import Downloads from '../layouts/downloads/downloads';
import EntryPoints from '../layouts/entry-points/entry-points';
import EventsLayout from '../layouts/events/events';
import FAQsLayout from '../layouts/faqs/faqs';
import FullLayout from '../layouts/full/full';
import GlossaryLayout from '../layouts/glossary/glossary';
import IconsLayout from '../layouts/icons/icons';
import ImageAndContent from '../layouts/campaign-image-content/campaign-image-content';
import InstagramLayout from '../layouts/instagram/instagram';
import LinkList from '../layouts/link-list/link-list';
import Login from '../layouts/login/login';
import NewsLayout from '../layouts/news/news';
import NewsWrapLayout from '../layouts/news-wrap/news-wrap';
import PlatformJobs from '../layouts/platform-jobs/platform-jobs';
import ProductOfferingsLayout from '../layouts/product-offerings/product-offerings';
import ProfilesLayout from '../layouts/profiles/profiles';
import Rates from '../layouts/rates/rates';
import Refer from '../layouts/refer/refer';
import SearchLayout from '../layouts/search/search';
import SEOCardsLayout from '../layouts/seo-cards/seo-cards';
import Separator from '../layouts/separator/separator';
import Share from '../layouts/share/share';
import StatisticsLayout from '../layouts/statistics/statistics';
import StatusList from '../layouts/status-list/status-list';
import SupportWorkerProfilesLayout from '../layouts/profile-cards-filters/profile-cards-filters';
import Testimonial from '../layouts/testimonial/testimonial';
import TextLayout from '../layouts/text/text';
import TrustpilotLayout from '../layouts/trustpilot/trustpilot';
import Typeform from '../layouts/typeform/typeform';
import TwoColumn from '../layouts/two-column/two-column';
import UpdatesLayout from '../layouts/updates/updates';
import VideoLayout from '../layouts/video/video';
import ZendeskChatbot from '../layouts/zendesk-chatbot/zendesk-chatbot';

import Analytics from '../config/analytics';
import { Link as PrismicLink } from 'prismic-reactjs';
import { PrismicConfig } from './config';

class Slices extends React.Component {
  renderSlices(data) {
    if (!data || !data.length) {
      return [];
    }

    let slices = [],
      count = 1;
    data.forEach((slice) => {
      switch (slice.slice_type) {
        case 'button':
          // eslint-disable-next-line no-case-declarations
          const to = slice.primary.button_link
            ? PrismicLink.url(
                slice.primary.button_link,
                PrismicConfig.linkResolver
              )
            : '/';
          slices.push(
            <a
              href={to}
              className='button -secondary'
              key={count}
              target={slice.primary.button_link.target}
              onClick={() => Analytics.link(to)}>
              {slice.primary.button_text}
            </a>
          );
          break;

        case 'full':
          slices.push(
            <FullLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'video':
          slices.push(
            <VideoLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'two_column':
          slices.push(
            <TwoColumn
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'testimonial':
          slices.push(<Testimonial primary={slice.primary} key={count} />);
          break;

        case 'banner':
          slices.push(<Banner primary={slice.primary} key={count} />);
          break;

        case 'content_toggle':
          slices.push(<ContentToggle primary={slice.primary} key={count} />);
          break;

        case 'text':
          slices.push(<TextLayout primary={slice.primary} key={count} />);
          break;

        case 'icons':
          slices.push(
            <IconsLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'profiles':
          slices.push(
            <ProfilesLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'product_offerings':
          slices.push(
            <ProductOfferingsLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'statistics':
          slices.push(
            <StatisticsLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'pull_quote':
          slices.push(<PullQuote primary={slice.primary} key={count} />);
          break;

        case 'call_to_action':
          slices.push(
            <CallToAction
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'faqs':
          slices.push(
            <FAQsLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'news':
          slices.push(<NewsLayout primary={slice.primary} key={count} />);
          break;

        case 'news_wrap':
          slices.push(<NewsWrapLayout primary={slice.primary} key={count} />);
          break;

        case 'updates':
          slices.push(<UpdatesLayout primary={slice.primary} key={count} />);
          break;

        case 'events':
          slices.push(<EventsLayout primary={slice.primary} key={count} />);
          break;

        case 'glossary':
          slices.push(<GlossaryLayout primary={slice.primary} key={count} />);
          break;

        case 'link_list':
          slices.push(
            <LinkList primary={slice.primary} items={slice.items} key={count} />
          );
          break;

        case 'downloads':
          slices.push(
            <Downloads
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'status':
          slices.push(
            <StatusList
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'nav_item':
          slices.push(
            <NavItem
              primary={slice.primary}
              items={slice.items}
              key={count}
              collapsedSubmenus={this.props.collapsedSubmenus}
              isDesktopLayout={this.props.isDesktopLayout}
            />
          );
          break;

        case 'checklist':
          slices.push(
            <Checklist
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'jobs':
        case 'careers':
          slices.push(
            <Careers primary={slice.primary} items={slice.items} key={count} />
          );
          break;

        case 'instagram':
          slices.push(
            <InstagramLayout
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'rates':
          slices.push(
            <Rates primary={slice.primary} items={slice.items} key={count} />
          );
          break;

        case 'login':
          slices.push(
            <Login primary={slice.primary} items={slice.items} key={count} />
          );
          break;

        case 'refer':
          slices.push(
            <Refer primary={slice.primary} items={slice.items} key={count} />
          );
          break;

        case 'share':
          slices.push(
            <Share primary={slice.primary} items={slice.items} key={count} />
          );
          break;

        case 'separator':
          slices.push(
            <Separator
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'trustpilot':
          slices.push(<TrustpilotLayout primary={slice.primary} key={count} />);
          break;

        case 'subpage':
          if (
            !this.props.subpage &&
            slice.primary.page &&
            slice.primary.page.uid
          ) {
            slices.push(<Subpage uid={slice.primary.page.uid} key={count} />);
          }
          break;

        case 'search':
          slices.push(<SearchLayout primary={slice.primary} key={count} />);
          break;

        case 'sw_profiles':
          slices.push(
            <SupportWorkerProfilesLayout primary={slice.primary} key={count} />
          );
          break;

        case 'seo_cards':
          slices.push(<SEOCardsLayout primary={slice.primary} key={count} />);
          break;

        case 'platform_jobs':
          slices.push(<PlatformJobs primary={slice.primary} key={count} />);
          break;

        case 'typeform':
          slices.push(<Typeform primary={slice.primary} key={count} />);
          break;

        case 'entry_points':
          slices.push(
            <EntryPoints
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'content_tabbed':
          slices.push(
            <ContentTabbed
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'campaign_header':
          slices.push(<CampaignHeader primary={slice.primary} key={count} />);
          break;

        case 'campaign_columns':
          slices.push(
            <CampaignColumns
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'campaign_two_column':
          slices.push(
            <CampaignTwoColumn primary={slice.primary} key={count} />
          );
          break;

        case 'campaign_image_content':
          slices.push(
            <ImageAndContent
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'campaign_feature_profiles':
          slices.push(
            <CampaignFeatureProfiles
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'campaign_testimonials':
          slices.push(
            <CampaignTestimonials
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'campaign_trustpilot':
          slices.push(
            <CampaignTrustpilot primary={slice.primary} key={count} />
          );
          break;

        case 'campaign_feature':
          slices.push(<CampaignFeature primary={slice.primary} key={count} />);
          break;

        case 'campaign_comparison_table':
          slices.push(
            <CampaignComparisonTable
              primary={slice.primary}
              items={slice.items}
              key={count}
            />
          );
          break;

        case 'campaign_one_column':
          slices.push(
            <CampaignOneColumn primary={slice.primary} key={count} />
          );
          break;

        case 'zendesk_chatbot':
          slices.push(<ZendeskChatbot primary={slice.primary} key={count} />);
          break;

        default:
          console.warn('Unknown slice type', slice);
      }
      count++;
    });
    return slices;
  }

  render() {
    return (
      <React.Fragment>{this.renderSlices(this.props.slices)}</React.Fragment>
    );
  }
}

export default Slices;
